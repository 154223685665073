<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/08-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">IQVIA一站式多渠道客户营销管理平台</div>
            <div class="head-explain-content">提供数字化技术创新理念</div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom: 10rem;">
            <div class="industry-box">
                <div class="card">
                    <img src="../assets/image/example/08-0销售效能管理.png"/>
                    <div class="title1">销售效能管理</div>
                    <div class="title2">面向医药和器械行业销售效能管理一站式数字化平台</div>
                    <div class="title3" @click="jumpRout('/sales-effectiveness','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/08-0销售运营管理.png"/>
                    <div class="title1">销售运营管理</div>
                    <div class="title2">专为生命科学行业设计打造的销售运营管理解决方案</div>
                    <div class="title3" @click="jumpRout('/sales-operation','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/08-0学术会议管理.png"/>
                    <div class="title1">学术会议管理</div>
                    <div class="title2">面向医药和器械行业销售效能管理一站式数字化平台</div>
                    <div class="title3" @click="jumpRout('/academic-conference','2')">了解详情</div>
                </div>
                <div class="card">
                    <img src="../assets/image/example/08-0市场准入与商务运营.png"/>
                    <div class="title1">市场准入与商务运营</div>
                    <div class="title2">面向医药和器械行业市场准入与商务运营管理</div>
                    <div class="title3" @click="jumpRout('/market-access','2')">了解详情</div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'销售效能管理,销售运营管理,学术会议管理,市场准入与商务运营' },
        ]
    },
    data() {
        return {
        };
    },

    mounted() {
        this.activeIndex = '2'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        jumpRout(route,index){
            this.activeIndex = index
            localStorage.setItem('headId',index);
            this.$router.push(route)
        }
    },
};
</script>

<style lang="less" scoped>
.industry-box{
    width: 139rem;
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 4rem 0 0 26.1rem;
    .card{
        width: 33.2rem;
        height: 41rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px 0px rgba(231,231,231,0.5);

        margin-top: 4rem;
    }
    img{
        width: 29.2rem;
        height: 20rem;
        margin-top: 2rem;
    }
    .title1{
        height: 2.8rem;
        font-size: 2.8rem;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #000000;
        line-height: 4.2rem;
        padding: 2rem;
        text-align: justify;
    }
    .title2{
        height: 3.4rem;
        width: 29.2rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 2rem;
        padding: 2rem;
        margin-top: 1.5rem;
        text-align: justify;
    }
    .title3{
        height: 3rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 3rem;
        padding-left: 2rem;
        margin-top: 4rem;
        text-align: justify;

        cursor: pointer;
    }
    .card:hover{
        transform: scale(1.05);
        transition:all 0.5s;
    }

}
</style>
